import React, { useCallback, useEffect, useState } from 'react';

import { Button, Dialog, Icon } from '@mui/material';
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import useSubscription from '@/hooks/account/useSubscription';
import useAccountContext from '@/hooks/context/useAccountContext';
import useUserTracking from '@/hooks/useUserTracking';
import { StripeProductModel } from '@/models/Stripe';
import PaymentAPI from '@/network/PaymentAPI';
import { handleApiError } from '@/utility/api';

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY || '');

const CheckoutForm = ({
  paymentIntentToken,
  onComplete,
  product,
}: {
  paymentIntentToken: string;
  onComplete: () => void;
  product: StripeProductModel;
}) => {
  const userTracking = useUserTracking();
  const [open, setOpen] = useState<boolean>(false);
  const { isSubscribed } = useSubscription();

  const handleCancelCheckout = () => {
    if (userTracking) userTracking.userCancelledCheckout(product, isSubscribed);
    setOpen(false);
    onComplete();
  };

  useEffect(() => {
    setOpen(!!paymentIntentToken);
  }, [paymentIntentToken]);

  return (
    <>
      <Dialog className="payment-modal" open={open} onClose={() => handleCancelCheckout()}>
        <div className="d-flex jc-space-between pos-abs r0 mr32 mt16">
          <Button className="icon-btn m0 ml-auto mb10" onClick={() => handleCancelCheckout()}>
            <Icon>close</Icon>
          </Button>
        </div>
        <EmbeddedCheckout />
      </Dialog>
    </>
  );
};

const StripeCheckout = ({ product, onComplete }: { product: StripeProductModel; onComplete: () => void }) => {
  const { accountId } = useAccountContext();
  const userTracking = useUserTracking();
  const { isSubscribed } = useSubscription();

  const [paymentIntentToken, setPaymentIntentToken] = useState<string>();

  const getPaymentIntent = useCallback(async () => {
    if (userTracking) userTracking.userInitiatedCheckout(product, isSubscribed);

    if (product.productType !== 'subscription') {
      try {
        if (!accountId || !product.draftCampaignId) return;
        const response = await PaymentAPI.getPaymentIntent({
          accountId,
          draftCampaignId: product.draftCampaignId,
          priceId: product.priceId,
          returnUrl: `${window.location.origin}/payment-confirmation/${product.platform}`,
        });
        setPaymentIntentToken(response.data.clientSecret);
      } catch (error: unknown) {
        onComplete();
        handleApiError({ error });
      }
      return;
    }

    try {
      if (!accountId) return;

      const response = await PaymentAPI.getSubscriptionPaymentIntent({
        accountId,
        priceId: product.priceId,
        returnUrl: `${window.location.origin}/payment-confirmation/${product.platform}`,
      });
      setPaymentIntentToken(response.data.clientSecret);
    } catch (error: unknown) {
      onComplete();
      handleApiError({ error });
    }
  }, [accountId, isSubscribed, onComplete, product, userTracking]);

  useEffect(() => {
    getPaymentIntent();
  }, [getPaymentIntent]);

  return (
    <>
      {paymentIntentToken && (
        <EmbeddedCheckoutProvider stripe={stripePromise} options={{ clientSecret: paymentIntentToken, onComplete }}>
          <CheckoutForm paymentIntentToken={paymentIntentToken} onComplete={onComplete} product={product} />
        </EmbeddedCheckoutProvider>
      )}
    </>
  );
};

export default StripeCheckout;
